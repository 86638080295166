var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-grid-row",
    [
      _c(
        "gov-grid-column",
        { attrs: { width: "two-thirds" } },
        [
          _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Global")]),
          _c("gov-body", [
            _vm._v("Review the global content for the frontend.")
          ]),
          _c("ck-text-input", {
            attrs: {
              value: _vm.frontend.global.footer_title,
              label: "Footer Title",
              error: _vm.errors.get("cms.frontend.global.footer_title"),
              id: "cms.frontend.global.footer_title"
            },
            on: {
              input: function($event) {
                return _vm.onInput({ field: "footer_title", value: $event })
              }
            }
          }),
          _c("ck-wysiwyg-input", {
            attrs: {
              value: _vm.frontend.global.footer_content,
              label: "Footer Content",
              error: _vm.errors.get("cms.frontend.global.footer_content"),
              id: "cms.frontend.global.footer_content"
            },
            on: {
              input: function($event) {
                return _vm.onInput({ field: "footer_content", value: $event })
              }
            }
          }),
          _c("ck-text-input", {
            attrs: {
              value: _vm.frontend.global.contact_phone,
              label: "Contact Phone Number",
              error: _vm.errors.get("cms.frontend.global.contact_phone"),
              id: "cms.frontend.global.contact_phone",
              type: "tel"
            },
            on: {
              input: function($event) {
                return _vm.onInput({ field: "contact_phone", value: $event })
              }
            }
          }),
          _c("ck-text-input", {
            attrs: {
              value: _vm.frontend.global.contact_email,
              label: "Contact Email",
              error: _vm.errors.get("cms.frontend.global.contact_email"),
              id: "cms.frontend.global.contact_email",
              type: "email"
            },
            on: {
              input: function($event) {
                return _vm.onInput({ field: "contact_email", value: $event })
              }
            }
          }),
          _c("ck-text-input", {
            attrs: {
              value: _vm.frontend.global.twitter_handle,
              label: "Twitter Handle",
              hint: "Without the @ infront.",
              error: _vm.errors.get("cms.frontend.global.twitter_handle"),
              id: "cms.frontend.global.twitter_handle"
            },
            on: {
              input: function($event) {
                return _vm.onInput({ field: "twitter_handle", value: $event })
              }
            }
          }),
          _c("ck-text-input", {
            attrs: {
              value: _vm.frontend.global.facebook_handle,
              label: "Facebook Handle",
              error: _vm.errors.get("cms.frontend.global.facebook_handle"),
              id: "cms.frontend.global.facebook_handle"
            },
            on: {
              input: function($event) {
                return _vm.onInput({ field: "facebook_handle", value: $event })
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }